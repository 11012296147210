import { redirect } from "@remix-run/node";
import type { ActionFunction, LoaderFunction, LoaderFunctionArgs } from "@remix-run/node";
import { Form, Link, useLoaderData } from "@remix-run/react";
import ButtonPrimary from "~/components/ButtonPrimary";
import Header from "~/components/Header";
import Input from "~/components/Input";
import { schoolYear } from "~/data/schoolYear.server";
import { useTranslation } from "react-i18next";
import i18n from "~/utils/i18n.server";
import LanguageSwitcher from "~/components/LanguageSwitcher";
import { db } from "~/utils/db.server";

export const handle = {
  i18n: "home",
};

export const action: ActionFunction = async ({ request }) => {
  const formData = await request.formData();

  const controlNumber = formData.get("controlNumber");
  return redirect(`/registration/${controlNumber}/permit-to-register`);
};

export async function loader({ request }: LoaderFunctionArgs) {
  const url = new URL(request.url);
  let sy = url.searchParams.get("sy");
  const defaultSchoolYear = await schoolYear()

  if (!sy) {
    sy = defaultSchoolYear;
  }
  const locale = await i18n.getLocale(request);

  return { schoolYear: sy, locale };
};

export default function Index() {
  const data = useLoaderData<typeof loader>();
  const { t } = useTranslation("home");

  return (
    <div className="relative lg:max-w-7xl mx-auto">
      <LanguageSwitcher locale={data.locale} sy={data.schoolYear} />
      <Header schoolYear={data.schoolYear} />
      <main className="bg-white min-h-screen">
        <div className="px-2 md:px-6 lg:px:4 text-gray-900">
          <div className="md:flex md:justify-between py-10 space-y-4 md:space-y-0 font-medium">
            <div className="px-10 md:border-r border-b md:border-b-0 border-gray-500 pb-10 md:pb-0 ">
              <h3 className="text-xl">{t("title1")}</h3>
              <p className="mt-2 ">{t("text1")}</p>
              <div className="pt-4">
                <Link to={`/registration?sy=${data?.schoolYear}`}>
                  <ButtonPrimary
                    type="button"
                    name="newRegButton"
                    text={t("newRegButtonLabel")}
                  />
                </Link>
              </div>
            </div>
            <div className="px-10">
              <h3 className="text-xl font-medium">{t("title2")}</h3>
              <p className="mt-2 ">{t("text2")}</p>
              <Form method="post" action="/?index">
                <div className="flex justify-between items-end space-x-2">
                  <div className="pt-4 w-3/4">
                    <Input
                      name="controlNumber"
                      id="controlNumber"
                      label="Control Number"
                      type="text"
                    />
                  </div>
                  <div className="md:w-5/12 text-sm md:text-base">
                    <ButtonPrimary
                      text={t("controlNumberButtonLabel")}
                      type="submit"
                    />
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export function ErrorBoundary() {
  return (
    <div className="">Something unexpected went wrong. Sorry about that.</div>
  );
}
